import React from 'react';
import './index.scss';
import {useMediaQuery} from "react-responsive";
import RouterDom from './routers/index';
import RouterMobile from './routers/RouterMobile';

function App() {
  const isMobile = useMediaQuery({maxWidth: 767});
  return (
    <div>
      {isMobile
        ? <RouterMobile/>
        : <RouterDom/>
      }
    </div>
    
  );
}

export default App;
