import GridRenderSingleton from "./GridRender";

export class FaderSingleton {
  private static instance: FaderSingleton;
  private gridRender: GridRenderSingleton;
  private timer: number;
  private id: number;
  private transitionTime: number;
  private completed: boolean;
  private fadedIn: boolean;

  constructor() {
    this.gridRender = GridRenderSingleton.getInstance();
    this.timer = 0;
    this.id = 0;
    this.transitionTime = 0.5;
    this.completed = true;
    this.fadedIn = true;
  }

  public static getInstance(): FaderSingleton {
    if (!FaderSingleton.instance) {
      FaderSingleton.instance = new FaderSingleton();
    }

    return FaderSingleton.instance;
  }

  public isCompleted() {
    return this.completed;
  }

  public isFadedIn() {
    return this.fadedIn;
  }

  public setFadedIn(bool: boolean) {
    this.fadedIn = bool;
  }

  public startFadeInOut(duration: number, delay: number, callback: () => void) {
    if (!this.completed || this.fadedIn) {
      return;
    }

    this.completed = false;
    this.transitionTime = duration;
    this.startComponents();
    this.fadeIn(delay, callback);
  }

  public startFadeOut(duration: number = 1.0) {
    if (!this.completed || !this.fadedIn) {
      return;
    }

    this.transitionTime = duration;
    this.fadedIn = false;
    this.completed = false;
    this.fadeOut();
  }

  public startFadeIn(duration: number = 0.5) {
    if (!this.completed || this.fadedIn) {
      return;
    }

    this.transitionTime = duration;
    this.completed = false;
    this.startComponents();
    this.fadeIn();
  }

  private startComponents() {
    this.fadedIn = true;
    const titlePanel = document.querySelector('.title-panel') as HTMLDivElement;
    const contentPanel = document.querySelector('.content-panel') as HTMLDivElement;
    titlePanel.style.zIndex = '2';
    contentPanel.style.pointerEvents = 'none';
    document.body.style.cursor = 'none';
  }

  private stopComponents() {
    const titlePanel = document.querySelector('.title-panel') as HTMLDivElement;
    const contentPanel = document.querySelector('.content-panel') as HTMLDivElement;
    setTimeout(() => {
      titlePanel.style.zIndex = '0';
    }, 100);
    contentPanel.style.pointerEvents = 'auto';
    document.body.style.cursor = 'auto';
  }

  private resetComponent() {
    this.timer = 0;
    setTimeout(() => {
      this.completed = true;
    }, 100);
    cancelAnimationFrame(this.id);
  }

  private fadeOut(timeOut?: number, callback?: () => void) {
    this.timer += 0.016;
    if (this.gridRender.fadeOut(this.timer, this.transitionTime)) {

      if (timeOut) {
        setTimeout(() => {
          if (callback) {
            callback();
          }
        }, timeOut * 0.5);

        setTimeout(() => {
          if (this.completed) {
            this.startFadeIn(this.transitionTime);
          }
        }, timeOut);
      }

      this.stopComponents();
      this.resetComponent();
      return;
    }

    this.id = requestAnimationFrame(() => this.fadeOut(timeOut, callback));
  }

  private fadeIn(timeOut?: number, callback?: () => void) {
    this.timer += 0.016;
    if (this.gridRender.fadeIn(this.timer, this.transitionTime)) {

      if (timeOut) {
        setTimeout(() => {
          if (callback) {
            callback();
          }
        }, timeOut * 0.5);

        setTimeout(() => {
          if (this.completed) {
            this.startFadeOut(this.transitionTime);
          }
        }, timeOut);
      }

      this.resetComponent();
      return;
    }

    this.id = requestAnimationFrame(() => this.fadeIn(timeOut, callback));
  }

}

export default FaderSingleton;