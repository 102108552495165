/* 
  This class is responsible for performing GPU-based grid-based computations 
  using Three.js and the GPUComputationRenderer. It sets up a GPGPU system 
  that tracks a grid, updates mouse interactions, and manages rendering and 
  computational shaders. It allows dynamic interaction with the grid via mouse 
  input and performs relaxation computations to produce fluid-like grid behavior.
*/

import * as THREE from 'three'
import { GPUComputationRenderer, Variable } from 'three/examples/jsm/misc/GPUComputationRenderer.js'
import { Size } from '../constants/types'
import { GPGPUShader } from '../shaders/GPGPUShader'

interface Props {
  renderer: THREE.WebGLRenderer
  scene: THREE.Scene
  sizes: Size
}

interface Params {
  relaxation: number
  size: number
  distance: number
  strength: number
}

export default class GPGPU {
  // Class properties
  time: number                            // Stores the current time for animations
  size: number                            // Grid size for the GPGPU computation
  ratio: number                           // Aspect ratio based on screen size
  sizes: Size                             // Holds screen sizes (width, height)
  gpgpuRenderer!: GPUComputationRenderer  // GPGPU renderer instance
  renderer: THREE.WebGLRenderer           // WebGL renderer
  dataTexture!: THREE.DataTexture         // Initial data texture used in GPGPU
  variable!: Variable                     // GPGPU variable for tracking grid updates
  targetVariable!: Variable               // Additional variable for targets (if needed)
  scene: THREE.Scene                      // Scene to which the GPGPU system belongs
  params: Params                          // Parameters for tuning behavior of the GPGPU system

  constructor({ renderer, scene, sizes }: Props) {
    // Assigning properties and setting initial parameters
    this.scene = scene
    this.renderer = renderer
    this.sizes = sizes
    this.ratio = sizes.width / sizes.height // Calculate aspect ratio
    
    this.params = {
      relaxation: 0.965,
      size: 256, //1024
      distance: 0.5,
      strength: 0.45,
    }

    // Compute square root of the grid size for grid dimensions
    this.size = Math.ceil(Math.sqrt(this.params.size))
    this.time = 0

    // Initialize GPGPU system components
    this.createGPGPURenderer()
    this.createDataTexture()
    this.createVariable()
    this.setRendererDependencies()
    this.initiateRenderer()
  }

  // Initialize the GPGPU renderer with dimensions and the WebGL renderer
  createGPGPURenderer() {
    this.gpgpuRenderer = new GPUComputationRenderer(this.size * this.ratio, this.size, this.renderer)
  }

  // Create a data texture to hold the initial state of the grid
  createDataTexture() {
    this.dataTexture = this.gpgpuRenderer.createTexture()
  }

  // Create a GPGPU variable and assign shader and uniforms for grid interactions
  createVariable() {
    this.variable = this.gpgpuRenderer.addVariable('uGrid', GPGPUShader.fragmentShader, this.dataTexture)

    this.variable.material.uniforms.uTime = new THREE.Uniform(0)
    this.variable.material.uniforms.uRelaxation = new THREE.Uniform(this.params.relaxation)
    this.variable.material.uniforms.uGridSize = new THREE.Uniform(this.size)
    this.variable.material.uniforms.uMouse = new THREE.Uniform(new THREE.Vector2(0, 0))
    this.variable.material.uniforms.uDeltaMouse = new THREE.Uniform(new THREE.Vector2(0, 0))
    this.variable.material.uniforms.uMouseMove = new THREE.Uniform(0)
    this.variable.material.uniforms.uDistance = new THREE.Uniform(this.params.distance * 10)
  }

  // Define the dependencies between GPGPU variables for consistent updates
  setRendererDependencies() {
    this.gpgpuRenderer.setVariableDependencies(this.variable, [this.variable])
  }

  // Initialize the GPGPU renderer (compiles shaders and prepares for computation)
  initiateRenderer() {
    this.gpgpuRenderer.init()
  }

  // Update the mouse position on the grid and calculate delta for interaction effects
  updateMouse(uv: THREE.Vector2) {
    this.variable.material.uniforms.uMouseMove.value = 1 // Mark mouse as moving

    // Calculate difference between current mouse position and previous
    const current = this.variable.material.uniforms.uMouse.value as THREE.Vector2
    current.subVectors(uv, current)
    current.multiplyScalar(this.params.strength * 100)

    // Update uniforms with new mouse position and delta
    this.variable.material.uniforms.uDeltaMouse.value = current
    this.variable.material.uniforms.uMouse.value = uv
  }

  // Retrieve the current texture after the GPGPU computation step
  getTexture() {
    return this.gpgpuRenderer.getCurrentRenderTarget(this.variable).textures[0]
  }

  // Return the size of the grid (for use in external logic)
  getSize() {
    return this.size
  }

  // Perform rendering and computation steps, updating uniforms over time
  render(time: number, deltaTime: number) {
    this.time = time

    this.variable.material.uniforms.uTime.value = this.time
    this.variable.material.uniforms.uMouseMove.value *= 0.95 // Fade mouse movement over time
    this.variable.material.uniforms.uDeltaMouse.value.multiplyScalar(this.variable.material.uniforms.uRelaxation.value) // Apply relaxation to mouse delta

    this.gpgpuRenderer.compute()
  }
}