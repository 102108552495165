import { Col, Row } from "antd";
import React, { useEffect, useRef } from "react";
import './list-project.scss';
import LineHome from "../HomeContent/LineHome";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import ProjectItem from "./ProjectItem";

const ListProject = () => {
  const width = useSelector((state: RootState) => state.sizeContent.width);
  const height = useSelector((state: RootState) => state.sizeContent.height);
  const widthTd = height * 0.8 / 3;
  const projectList = useSelector((state: RootState) => state.projectList);
  const ref = useRef<any>();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const _renderHeader = () => {
    return (
      <div
        className={'header-list-project'}
        style={{
          height: widthTd,
          '--padding-vertical': `${widthTd * 0.5}px`,
        } as React.CSSProperties}
      >
        <p className='title-experiments' style={{ fontSize: width / 20 }}>
          Experiments
        </p>
        <p
          className={'description-experiments'}
          style={{
            fontSize: width / 130
          }}
        >
          "To experiment is to invite challenges, and sometimes, chaos."
        </p>
      </div>
    )
  };

  const _renderListProject = () => {
    return (
      <Row gutter={0} className={'list-project-bg'}>
        {projectList.map((item, index) => {
          return (
            <Col
              xs={12}
              key={index}
              className={'col-item-project'}
              style={{
                height: widthTd
              }}
            >
              <ProjectItem project={item} index={index} />
            </Col>
          )
        })}
      </Row>
    );
  };

  return (
    <div ref={ref}
      className="list-project-container"
      style={{
        //  minHeight: 3 * widthTd,
        height: height * 0.8
      }}>
      {_renderHeader()}
      {_renderListProject()}
      <LineHome
        row={Math.round(projectList.length / 2)}
        isList={true}
      />
    </div>
  )
};

export default ListProject;
