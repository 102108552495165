import {Layout} from "antd";
import React, {useEffect, useState} from "react";
import './desktop.scss';
import HeaderDesktop from "./HeaderDesktop";
import FooterDesktop from "./FooterDesktop";
import ContentDesktop from "./ContentDesktop";
import {useDispatch, useSelector} from "react-redux";
import {changeSizeContent} from "../../redux/actions/sizeContent";
import {RootState} from "../../redux/reducers";

const Desktop = () => {
  const dispatch = useDispatch();
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  const screenHeight = window.innerHeight || document.documentElement.clientHeight;
  const [widthScreen, setWidthScreen] = useState(screenWidth);
  const [heightScreen, setHeightScreen] = useState(screenHeight);
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  // console.log("Layout Desktop");

  useEffect(() => {
    if (widthScreen / heightScreen === 18 / 9) {
      dispatch(changeSizeContent({width: widthScreen, height: heightScreen}));
    } else if (widthScreen / heightScreen > 18 / 9) {
      dispatch(changeSizeContent({width: heightScreen * 18 / 9, height: heightScreen}));
    } else if (widthScreen / heightScreen < 18 / 9) {
      dispatch(changeSizeContent({width: widthScreen, height: widthScreen / 18 * 9}));
    }
  }, [widthScreen, heightScreen]);

  window.addEventListener('resize', () => {
    const newScreenWidth = window.innerWidth || document.documentElement.clientWidth;
    const newScreenHeight = window.innerHeight || document.documentElement.clientHeight;
    setHeightScreen(newScreenHeight);
    setWidthScreen(newScreenWidth);
  });

  return (
    <Layout className='layout-desktop-container'>
      <div
        className="content"
        style={{
          width: widthContent,
          height: heightContent,
          animation: 'fadeInContent 2s ease-in-out'
        }}>
        <HeaderDesktop/>
        <ContentDesktop/>
        <FooterDesktop/>
      </div>
    </Layout>
  )
};


export default Desktop;
