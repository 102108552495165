/*
  This class creates and manages a Three.js canvas with interactive media (video) 
  textures. It handles the camera, scene, renderer, raycasting for mouse 
  interaction, and resizing logic. The class also renders the media using a 
  Media class instance, which applies custom shaders and deformations to 
  the video element.
*/

import * as THREE from 'three'
import { Dimensions, Size } from '../../../constants/types'
import Media from './media'
import gsap from 'gsap'

export default class Canvas {
  element: HTMLCanvasElement        // The HTML canvas element where the Three.js scene is rendered
  scene!: THREE.Scene               // The Three.js scene containing all objects and lights
  camera!: THREE.PerspectiveCamera  // Perspective camera for the scene
  renderer!: THREE.WebGLRenderer    // The renderer for displaying the scene on the canvas
  sizes!: Size                      // Dimensions of the media plane
  dimensions!: Dimensions           // Dimensions of the window (for resizing and pixel ratio)
  time: number                      // Timestamp for rendering and animation
  clock!: THREE.Clock               // Clock used for managing time-based rendering updates
  raycaster!: THREE.Raycaster       // Raycaster for detecting mouse interactions with 3D objects
  mouse!: THREE.Vector2             // Mouse coordinates, normalized for Three.js interactions
  media!: Media                     // Media instance that manages the video texture and interaction

  constructor() {
    // Grabbing the canvas element by its ID
    this.element = document.getElementById('webgl') as HTMLCanvasElement
    this.time = 0
    
    this.createClock()
    this.createScene()  
    this.createCamera()
    this.createRenderer()
    this.setSizes()
    this.createRayCaster()
    this.addEventListeners()    
    this.createMedia()
    this.render()
  }

  // Create the Three.js scene
  createScene() {
    this.scene = new THREE.Scene()
  }

  // Create and position the camera in the scene
  createCamera() {
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100)
    this.scene.add(this.camera)
    this.camera.position.z = 10
  }

  // Set up the WebGL renderer and adjust its settings
  createRenderer() {
    this.dimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: Math.min(2, window.devicePixelRatio),  // Set a max pixel ratio of 2 for high-DPI displays
    }

    // Create the WebGLRenderer with transparency
    this.renderer = new THREE.WebGLRenderer({ canvas: this.element, alpha: true })
    this.renderer.setSize(this.dimensions.width, this.dimensions.height)
    this.renderer.render(this.scene, this.camera)
    
    this.renderer.setPixelRatio(this.dimensions.pixelRatio)
  }

  // Set the media's width and height based on the camera's field of view and window size
  setSizes() {
    let fov = this.camera.fov * (Math.PI / 180)
    let height = this.camera.position.z * Math.tan(fov / 2) * 2
    let width = height * this.camera.aspect
    
    this.sizes = {
        width: width,
        height: height,
    }
  }

  // Initialize the clock to track elapsed time
  createClock() {
    this.clock = new THREE.Clock()
  }

  // Create the raycaster and initialize mouse coordinates for interaction
  createRayCaster() {
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
  }

  // Handle mouse movement and interact with objects in the scene using raycasting
  onMouseMove(event: MouseEvent) {
    // Animate the mouse coordinates using GSAP for smooth interaction
    gsap.to(this.mouse, 0.2, {
      x: (event.clientX / window.innerWidth) * 2 - 1, // Normalize mouse X position to range -1 to 1
      y: -(event.clientY / window.innerHeight) * 2 + 1 // Normalize mouse Y position to range -1 to 1
    })

    this.raycaster.setFromCamera(this.mouse, this.camera) 
    const intersects = this.raycaster.intersectObjects(this.scene.children)
    const target = intersects[0]
    if (target && 'material' in target.object) {
      const targetMesh = target.object as THREE.Mesh
      if (this.media.mesh === targetMesh && target.uv) {
        this.media.onMouseMove(target.uv)
      }
    }
  }

  // Add event listeners for mouse movement and window resizing
  addEventListeners() {
    window.addEventListener('mousemove', this.onMouseMove.bind(this))
    window.addEventListener('resize', this.onResize.bind(this))
  }

  // Handle window resize events and adjust the canvas size, camera, and media dimensions
  onResize() {
    this.dimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: Math.min(2, window.devicePixelRatio),
    }

    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.setSizes()

    this.renderer.setPixelRatio(this.dimensions.pixelRatio)
    this.renderer.setSize(this.dimensions.width, this.dimensions.height)
    this.renderer.setClearColor(0x000000, 1)

    this.media.onResize(this.sizes)
  }

  // Create the media instance, binding a video element to the media class for rendering
  createMedia() {
    // Grab the video element
    const image = document.querySelector('.title-video-player') as HTMLVideoElement
    const media = new Media({
      element: image,
      scene: this.scene,
      sizes: this.sizes,
      renderer: this.renderer,
      mouse: this.mouse,
    })

    this.media = media
  }

  // Main render loop for updating the scene
  render() {
    this.time = this.clock.getElapsedTime()

    this.media.render(this.time)
    
    this.renderer.render(this.scene, this.camera)
  }
}