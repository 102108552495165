/*
  This function asynchronously processes an image to extract its grayscale 
  pixel values. It scales the image to the provided width and height, 
  draws it onto a canvas, and then calculates grayscale values for each 
  pixel. These grayscale values are normalized between 0 and 1, and returned 
  in an array. The function uses the HTML canvas API to manipulate image data.
*/
export const processGrayscaleImage = async (
  imageSrc: string, // Source of the image to process
  width: number,    // Desired width for processing the image
  height: number    // Desired height for processing the image
): Promise<number[]> => {
  
  return new Promise((resolve, reject) => {
    const img = new Image(); // Create a new image object
    img.src = imageSrc;      // Set the image source

    // Event handler for when the image is successfully loaded
    img.onload = () => {
      // Create a canvas and get its 2D drawing context
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      if (!context) {
        // Reject the promise if canvas context cannot be obtained
        reject(new Error('Could not get canvas context'));
        return;
      }

      // Set canvas dimensions to match the desired processing size
      canvas.width = width;
      canvas.height = height;
      
      // Draw the image onto the canvas, scaling it to the specified size
      context.drawImage(img, 0, 0, width, height);

      // Extract pixel data from the canvas (RGBA format)
      const imageData = context.getImageData(0, 0, width, height);
      const data = imageData.data; // Raw pixel data (R, G, B, A per pixel)
      const grayscaleValues: number[] = []; // Array to hold calculated grayscale values

      // Iterate over the pixel data in chunks of 4 (R, G, B, A for each pixel)
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];     // Red value
        const g = data[i + 1]; // Green value
        const b = data[i + 2]; // Blue value

        // Calculate the grayscale value as the average of R, G, and B, normalized to 0-1
        const gray = (r + g + b) / 3 / 255;
        grayscaleValues.push(gray); // Store the grayscale value
      }

      // Resolve the promise with the array of grayscale values
      resolve(grayscaleValues);
    };

    // Event handler for image loading errors
    img.onerror = (error) => {
      // Reject the promise if the image fails to load
      reject(new Error(`Failed to load image: ${error}`));
    };
  });
};
