import { useLocation, useNavigate } from "react-router-dom";
import { RouterName } from "../../../enum/RouterEnum";
import * as animationData from '../../../assets/Json/TOL_512.json';
import { getSettingGifDefault } from "../../../ultils/getSettingGif";
import './textHeader.scss' 
import Lottie from "react-lottie";
import FaderSingleton from "../../../ultils/Fader";

const TextHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMainIndexPage = location.pathname === '/';
  const fader = FaderSingleton.getInstance();

  const _transitionToHome = () => {
    fader.startFadeIn(0.5);
      setTimeout(() => {
        _goHome();
      }, 500);
  }

  const _goHome = () => {
    navigate(RouterName.dashboard);
  };

  return (
    <div id='image-content-home' className={'image-content-home'} onClick={_transitionToHome}>
      <Lottie 
        options={getSettingGifDefault(animationData)}
        isClickToPauseDisabled={true}
      />
    </div>
  )
}

export default TextHeader;
