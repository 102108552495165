const LoadingCapabilities = () => {
  return (
    <div className='content-left-box'>
      <div className="box-left-content">
        <p className='text-gray'>{'< Loading Capabilities >'}</p>
        <p className="text-loading-left">{'< loading > creative direction : OK'}</p>
        <p className="text-loading-left">{'< loading > branding design: OK'}</p>
        <p className="text-loading-left">{'< loading > interaction design : OK'}</p>
        <p className="text-loading-left">{'< loading > character design : OK'}</p>
        <p className="text-loading-left">{'< loading > motion design : OK'}</p>
        <p className="text-loading-left">{'< loading > production : OK'}</p>
        <p className="text-loading-left">{'< loading > front-end : OK'}</p>
        <p className="text-loading-left">{'< loading > back-end : OK'}</p>
        <p className="text-loading-left">{'< loading > ui.ux : OK'}</p>
        <p className="text-loading-left">{'< loading > web 3.0 : OK'}</p>
      </div>
    </div>
  )
};

export default LoadingCapabilities
