import React, { useEffect, useRef, useState } from "react";
import './page-detail.scss';
import ImageBase from "../../../elements/Image";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useLocation } from "react-router-dom";
import { ProjectModel } from "../../../models/Project";
import "@lottiefiles/lottie-player";
import ProjectLottie from "./ProjectLottie";
import { isSafari } from 'react-device-detect';

const ProjectDetailDesktop = () => {
  const ref = useRef<any>();
  const height = useSelector((state: RootState) => state.sizeContent.height);
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const location = useLocation();
  const [project, setProject] = useState<ProjectModel>({} as ProjectModel);
  const className = isSafari ? 'project-client-title-safari' : 'project-client-title';

  useEffect(() => {
    if (location && location.state) {
      setProject(location.state);
    }
    const topElement = document.getElementById('header-project-detail');
    if (topElement) {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    // Sean Ngo: Hides the scrolling event in the projects menu
    document.body.style.overflow = 'hidden';
    // window.addEventListener('scroll', handleScroll, {passive: false});

    return () => {
      document.body.style.overflow = 'auto';
      // window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderProjectName = () => {
    return (
      <div className={'text-title-container-detail'}>
        <p className={className}>{'< CLIENT >'}</p>
        <p className={'text-project-title'}>{project.title}</p>
        <p
          className='text-title-detail'
          style={{
            fontSize: height / 13
          }}
        >
          {project.detailTitle}
        </p>
      </div>
    )
  };

  const _renderProjectInfo = () => {
    return (
      <div className={'project-detail-info-container2'}>
        <div className={'info-left'}>
          <p className={className}>{`< THE EXPERIMENT >`}</p>
          <p className={'detail_description-project'}>{project.detailDescription}</p>
        </div>
        <div className={'info-right'}>
          <p className={className}>{`< SERVICES >`}</p>
          <div className={'description-detail'}>
            <div className={'left'}>
              {project && project.services && project.services.map((item, index) => {
                if (index % 2 === 0) return (<div className={'project-client'} key={item}> {item} <br /></div>)
              })}
            </div>
            <div className={'right'}>
              {project && project.services && project.services.map((item, index) => {
                if (index % 2 !== 0) return (<div className={'project-client'} key={item}> {item} <br /></div>)
              })}
            </div>
          </div>
        </div>
      </div>
    )
  };

  const _renderProjectImage = (item: string, index: number) => {
    if (item.includes('.json')) {
      return (
        <div className={'image-container'} key={index}>
          <ProjectLottie
            link={item}
          />
        </div>
      )
    } else if (item.includes('.mp4')) {
      return (
        <video className={'video-project'}
          autoPlay loop muted playsInline src={item} />
      )
    } else {
      return (
        <div className={'image-container'} key={index}>
          <ImageBase
            src={item}
            alt={''}
            className={'images-project'}
          />
        </div>
      )
    }
  };

  return (
    <div ref={ref}
      className={'project-detail-container'}
      style={{ height: height * 0.8 }}
      id={'header-project-detail'}
      key={project.id}
    >
      <div
        className='project-detail-info-container'
        style={{
          fontSize: widthContent / 160,
        }}
      >
        {_renderProjectName()}
        {_renderProjectInfo()}
      </div>
      {project && project.detailImages && project.detailImages.map((item, index) => {
        return _renderProjectImage(item, index)
      })}
    </div>
  )
};

export default ProjectDetailDesktop;
