/*
  This file defines a grid system where individual cells can be manipulated
  based on the mouse position. Each cell has opacity and a timer, and their 
  data is passed to a shader for rendering. The grid system ensures that new 
  cells are added to the buffer, while old cells are reset or updated as 
  necessary.
*/

import * as THREE from 'three'

// Class representing individual cells in the grid system
class CellData {
  pos: THREE.Vector2  // The position of the cell in the grid
  o: number           // The opacity value of the cell
  t: number           // A timer used to manage the cell's state

  constructor(pos: THREE.Vector2 = new THREE.Vector2(-1, -1), o: number = 0.0, t: number = 0.0) {
    this.pos = pos
    this.o = o
    this.t = t
  }

  // Copies data from another cell into this one
  copy(data: CellData) {
    this.pos = data.pos
    this.o = data.o
    this.t = data.t
  }

  // Resets the cell data to its initial state
  reset() {
    this.pos.x = -1
    this.pos.y = -1
    this.o = 0.0
    this.t = 0.0
  }
}

// Class representing the entire grid system
export class CellGridSystem {

  private shaderData: THREE.Vector3[] // Array used to store the cell data for the shader
  private dataBuffer: CellData[]      // Temporary buffer for new cells before being processed
  private data: CellData[]            // Main container for all cell data in the grid
  private capacity: number            // Maximum number of cells allowed in the grid
  private gridSize: THREE.Vector2     // Dimensions of the grid

  // Ensure that DeformationShader NUM_OPACITY is equivalent to the capacity
  constructor(gridSize: THREE.Vector2) {
    this.capacity = 128 // 512
    this.data = Array.from({ length: this.capacity }, () => new CellData())
    this.dataBuffer = []
    this.shaderData = Array.from({ length: this.capacity }, () => new THREE.Vector3(-1, -1, 0))
    this.gridSize = gridSize
  }

  // Returns the current shader data
  getShaderData() {
    return this.shaderData
  }

  // Main logic function for updating the grid based on mouse position
  updateGrid(uv: THREE.Vector2, radius: number = 2): void {
    const gridCoords = this.mouseToGrid(uv)
    const capacity = this.capacity

    // Helper function to calculate opacity based on distance from center
    const calculateOpacity = (distance: number): number => {
      const maxDistance = (radius == 0) ? 1 : radius
      const normalizedDistance = Math.min(distance / maxDistance, 1) // Normalize distance to be between 0 and 1
      return (1.0 - normalizedDistance) * (1.0 - normalizedDistance) * 1.0
    }

    // Helper function to add cells into the buffer
    const updateCellBuffer = (x: number, y: number, opacity: number, step: number): void => {
      const data = new CellData(new THREE.Vector2(x, y), opacity, step)
      this.dataBuffer.push(data)
    }

    // Processes buffered cell data and updates the main grid
    const updateCellsFromBuffer = (): void => {
      const currentCellCount = this.data.filter(v => v.t > 0.0).length
      if (currentCellCount + this.dataBuffer.length > capacity) {
        // Remove the oldest cells to make room for the new cells from the buffer
        const cellsToRemove = (currentCellCount + this.dataBuffer.length) - capacity

        // Shift cells to the left
        for (let i = 0; i < capacity - cellsToRemove; i++) {
          const element = this.data[i + cellsToRemove]
          this.data[i].copy(element)
        }

        // Reset the removed cells
        for (let i = 1; i < cellsToRemove + 1; i++) {
          this.data[capacity - i].reset()
        }
      }

      // Add new cells from the buffer
      const bufferSize = Math.min(this.dataBuffer.length, capacity) // Get the smaller of buffer size or capacity
      for (let i = 0; i < bufferSize; i++) {
        let buffer = this.dataBuffer[i]
        const index = this.data.findIndex(v => v.pos.x === buffer.pos.x && v.pos.y === buffer.pos.y)

        // If the cell already exists, update its opacity
        if (index !== -1) {
          const newOpacity = (this.data[index].o < buffer.o) ? buffer.o : this.data[index].o
          buffer.o = newOpacity

          // Shift cells to accommodate updates
          for (let i = index; i < capacity - 1; i++) {
            const element = this.data[i + 1]
            this.data[i].copy(element)
          }
        }

        // Add new opacity if we have space
        const firstEmptyIndex = this.data.findIndex(v => v.pos.x === -1 && v.pos.y === -1)
        if (firstEmptyIndex !== -1) {
          this.data[firstEmptyIndex].copy(buffer)
        }
      }

      // Clear the buffer after copying its data
      this.dataBuffer = []
    }

    // Iterate through the surrounding cells within the specified radius
    const range = 4 // 3
    for (let dx = -radius; dx <= radius; dx++) {
      for (let dy = -radius; dy <= radius; dy++) {
        // Calculate the distance from the center
        const distance = Math.sqrt(dx * dx + dy * dy)
        const neighborX = gridCoords.x + dx
        const neighborY = gridCoords.y + dy
        const opacity = calculateOpacity(distance)
        if (Math.abs(dx) <= range && Math.abs(dy) <= range && distance < range) {
          updateCellBuffer(neighborX, neighborY, opacity, opacity)
        }
        else if (Math.random() > 0.7) {
          const checker = (opacity < 0.9) ? 0.0 : 1.0
          //updateCellBuffer(neighborX, neighborY, checker, opacity)
        }
      }
    }

    updateCellsFromBuffer()
  }

  // Updates the timer and opacity of each cell over time
  updateCells(): void {
    // Fade out cells
    for (let i = 0; i < this.capacity; i++) {
      if (this.data[i].t >= 0.5) {
        this.data[i].o = 1.0
      }
      else if (this.data[i].t < 0.1 && this.data[i].t > 0.0) {
        this.data[i].o = 0.0
      }

      if (this.data[i].t < 0.0) {
        this.data[i].reset()
      }

      this.data[i].t -= 0.016 * 4.0
    }

    // Update shader data based on current cell state
    for (let i = 0; i < this.capacity; i++) {
      const data = this.data[i]
      this.shaderData[i].set(data.pos.x, data.pos.y, data.o)
    }
  }

  // Converts mouse coordinates to corresponding grid cell coordinates
  mouseToGrid(mouse: THREE.Vector2): { x: number; y: number } {
    // Calculate grid cell size
    const cellSizeX = 1 / this.gridSize.x // Size of each grid cell in x direction
    const cellSizeY = 1 / this.gridSize.y // Size of each grid cell in y direction

    const gridX = Math.floor(mouse.x / cellSizeX)
    const gridY = Math.floor(mouse.y / cellSizeY)

    return { x: gridX, y: gridY }
  }

}