import React, {useEffect} from 'react'
import {RandomReveal} from 'react-random-reveal';

interface Props {
  handleNextStep: () => void;
}

export default function LoadingCapabilities(props: Props) {
  const {handleNextStep} = props;

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, {passive: false});

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderTextLoad = (title: string) => {
    return (
      <RandomReveal
        isPlaying
        duration={2}
        characters={title}
        revealDuration={1}
      />
    )
  };

  const _renderContentItem1 = () => {
    return (
      <div className="home-loading-mobile">
        <p className='text-gray'>{_renderTextLoad('< Loading Capabilities >')}</p>
        <p>{_renderTextLoad('< loading > creative direction : 0K')}<br/>
          {_renderTextLoad('< loading > branding design: 0K')}<br/>
          {_renderTextLoad('< loading > interaction design : 0K')}<br/>
          {_renderTextLoad('< loading > character design : 0K')}<br/>
          {_renderTextLoad('< loading > motion design : 0K')}<br/>
          {_renderTextLoad('< loading > production : 0K')}<br/>
          {_renderTextLoad('< loading > front-end : 0K')}<br/>
          {_renderTextLoad('< loading > back-end : 0K')}<br/>
          {_renderTextLoad('< loading > ui.ux : 0K')}<br/>
          {_renderTextLoad('< loading > web 3.0 : 0K')}<br/>
        </p>
        <p className='text-gray'>{'<>'}</p>
      </div>
    )
  };

  const _renderContentItem2 = () => {
    return (
      <div className="home-loading-mobile">
        <p className='text-gray'>{_renderTextLoad('< Trust The Process >')}</p>
        <p>
          {_renderTextLoad('To experiment is to invite challenges,')}
          <br/>
          {_renderTextLoad('and sometimes, chaos.')}
          <br/>
          <br/>
          {_renderTextLoad("And now, we're inviting you to the lab")}
          <br/>
          {_renderTextLoad("to watch it all happen. ")}
        </p>
        <p className='text-gray'>{'<>'}</p>
        <p
          style=
            {{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
          {_renderTextLoad('Awaiting next steps ...')}
          <span className="span-text-loading">{'>>>'}</span>
        </p>
      </div>
    )
  };

  return (
    <div className={'div-loading-container'}>
      <div className={`loading-first-mobile`}>
        {_renderContentItem1()}
        {_renderContentItem2()}
      </div>

    </div>
  )
};

