import { useLocation, useNavigate } from "react-router-dom";
import { ProjectModel } from "../../../models/Project";
import { RouterName } from "../../../enum/RouterEnum";
import { useEffect, useState } from "react";
import { isSafari } from 'react-device-detect';
import { RootState } from '../../../redux/reducers';
import { useSelector } from "react-redux";
import FaderSingleton from "../../../ultils/Fader";

interface Props {
  listProject: ProjectModel[];
  idActive: number;
}

const ListProject = (props: Props) => {
  const { listProject, idActive } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isList = location.pathname.includes('list-project');
  const isHome = location.pathname === '/';
  const [orderIndex, setOrderIndex] = useState(0);
  const classNameText = isSafari ? 'text-gray-safari' : 'text-gray';
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const fader = FaderSingleton.getInstance();

  useEffect(() => {
    const projectIndex = listProject.findIndex(item => item.id === idActive);
    if (projectIndex > -1) {
      setOrderIndex(projectIndex + 1);
    } else {
      setOrderIndex(0);
    }
  }, [idActive]);

  const _goToList = () => {
    navigate(RouterName.listProject);
  };

  const _transitionToHome = () => {
    if (!isHome) {
      // Transition over here needed before navigating
      fader.startFadeInOut(0.5, 500, _goToHome);
    }
  }

  const _goToHome = () => {
    navigate(RouterName.dashboard);
  };

  const _gotoDetail = (project: ProjectModel) => {
    let charExits = /\W/g;
    const path = project.title.replaceAll(" ", "_").replace(charExits, '');
    navigate(`${RouterName.projectDetail}/${path}`, {
      state: project
    });
  };

  return (
    <div className={'header-right-box'}>
      <p className={classNameText}>{"< Menu >"}</p>
      <p
        className={
          isHome
            ? 'text-title-experiments-active'
            : 'text-title-experiments'
        }
        onClick={_goToHome} //_transitionToHome
        style={{
          fontSize: widthContent / 160,
        }}
      >
        THE OTHER LAB
      </p>
      <p
        className={
          isList
            ? 'text-title-experiments-active'
            : 'text-title-experiments'
        }
        onClick={_goToList}
        style={{
          fontSize: widthContent / 160,
        }}
      >EXPERIMENTS
        <span className="number-project">{orderIndex
          ? orderIndex.toString().padStart(2, '0') : ''}
          /{listProject.length.toString().padStart(2, '0')}
        </span>
      </p>
      <div className={'list-project-name'}>
        {listProject.map((item, index: number) => {
          return <p
            className={idActive === item.id ? "text-title-project-active" : 'text-title-project'}
            key={index}
            onClick={() => _gotoDetail(item)}
          >
            {`${(index + 1).toString().padStart(2, '0')}`}/ {item.detailTitle}
          </p>
        })}
      </div>
      <p className={isSafari ? "text-gray-safari2" : 'text-gray'}>{"<>"}</p>
    </div>
  )
};

export default ListProject;
