import './project-item.scss';
import { ProjectModel } from "../../../models/Project";
import LottiePlayer from '../../Desktop/ListProject/LottiePlayer';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { RouterName } from '../../../enum/RouterEnum';

interface Props {
  project: ProjectModel;
  index: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  goToReach: () => void;
}

const ProjectMobileItem = (props: Props) => {
  const { project, index, handlePrevStep, handleNextStep, goToReach } = props;
  const navigate = useNavigate();
  const colorTitle = (project.titleColor !== '#000000' && project.titleColor !== '#000000') ? project.titleColor : "black";
  const colorValue = (project.titleColor !== '#000000' && project.titleColor !== '#000000') ? project.titleColor : "#4d4d4d";

  const _changeShowDetail = () => {
    let charExits = /\W/g;
    const path = project.title.replaceAll(" ", "_").replace(charExits, '');
    navigate(`${RouterName.projectDetail}/${path}`, {
      state: project
    })
  };

  const _renderFooter = () => {
    return (
      <div className={`footer-intro-mobile2`}>
        <div className={`text-footer-mobile`}>
          <LeftOutlined
            className="icon-transfer"
            onClick={handlePrevStep}
          />
          <p onClick={goToReach} className={'text-create-mobile'}>
            LET'S CREATE CHEMISTRY
          </p>
          <RightOutlined
            className="icon-transfer"
            onClick={handleNextStep}
          />
        </div>
      </div>
    )
  };

  const _renderContent = () => {
    return (
      <div className='project-info-mobile'>
        <p className='p-title' style={{ color: colorTitle }}>CLIENT</p>
        <p className='p-title-value' style={{ color: colorTitle }}>{project.title}</p>
        <br />
        <p className='p-title2' style={{ color: colorTitle }}>EXPERIMENT</p>
        <p className='p-services' style={{ color: colorValue }}>{project.detailTitle}</p>
        <br />
        <p className='p-title2' style={{ color: colorTitle }}>SERVICES</p>
        {project.services.map((item, index : number) => {
          return (
            <p key={index} className='p-services' style={{ color: colorValue }}>{item}</p>
          )
        })}
      </div>
    )
  };

  return (
    <div key={index} className={'project-list-home'}>
      <div className="projects-list-mobile-container">
        <div className='project-mobile-container' key={index}  onClick={() => _changeShowDetail()}>
          <div className='image-project-mobile'>
          {/* <LottiePlayer link={project.image} /> */}
            <video
              autoPlay 
              loop muted 
              key={index} 
              playsInline 
              src={project.image}
            />
          </div>
          {_renderContent()}
          <p className='index-project'>
            {`/${(index + 1).toString().padStart(2, '0')}`}
          </p>
          <div className={'button-container'}>
            <button className={'button-view-detail'}>VIEW THE WORK</button>
          </div>
        </div>
      </div>
      {/*{_renderFooter()}*/}
    </div>
  )


};

export default ProjectMobileItem;
