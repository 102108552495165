import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useEffect, useRef } from 'react';
import Canvas from '../../../component/Desktop/Title/canvas';
import titleVideo from '../../../assets/video/Showreel.mp4';
import titleImage from '../../../assets/image/BG.png';
import TextHeader from '../../../component/Desktop/TextHeader';

export default function TitleDesktop() {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  const canvasRef = useRef<Canvas | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Initialize the Canvas instance
    // if(!canvasRef.current) {
    //   canvasRef.current = new Canvas();
    //   console.log(canvasRef);
    // }

    const renderCanvas = () => {
      if (canvasRef.current) {
        canvasRef.current.render();
        requestAnimationFrame(renderCanvas);
      }
    };

    // Start the animation loop
    renderCanvas();

    return () => {
      canvasRef.current = null;
    }
  }, []);

  useEffect(() => {
    const video = document.querySelector('.video-container video') as HTMLVideoElement | null;

    const renderCanvas = () => {
      if (canvasRef.current) {
        canvasRef.current.render();
        requestAnimationFrame(renderCanvas);
      }
    };

    function setupRenderCanvas() {
      if (containerRef.current && !canvasRef.current) {
        canvasRef.current = new Canvas();
        // console.log(canvasRef);

        renderCanvas();
      }
    }

    if (video) {
      video.addEventListener('playing', setupRenderCanvas);
    }

    return () => {
      if (video) {
        video.removeEventListener('playing', setupRenderCanvas);
      }
    };
  }, []);

  return (
    <div className='title-desktop' ref={containerRef}
      style={{
        height: window.innerHeight * 2,
        width: window.innerWidth,
        // '--offset-from-top': `${-(window.innerHeight - heightContent) * 0.5}px`,
        // '--offset-from-left': `${-(window.innerWidth - widthContent) * 0.5}px`,
      } as React.CSSProperties}
    >
      <div id='app'>
        <div className='title-icon'>
          <TextHeader />
        </div>
        <div className='container'>
          <div className='wrapper'>
            <div className='video-container'>
              <video className='title-video-player'
                autoPlay
                loop muted
                playsInline
                src={titleVideo}
                data-hover={titleImage}
                style={{
                  width: window.innerWidth,
                  height: window.innerHeight
                }}
              />

            </div>
          </div>
        </div>
        <canvas id="webgl" />
        <div className='scroll-indicator'>
          <span className="scroll-text">SCROLL</span>
          <div className="scroll-bar">
            <div className="scroll-line"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
