import React, {useEffect, useRef, useState} from "react";
import './sider-left.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";
import Intro from "./Intro";
import LoadingCapabilities from "./LoadingCapabilities"
import Processing from "./Processing";
import {handleWheel} from "../../../ultils/common";
import { isSafari } from 'react-device-detect';

const Index = () => {
  const ref = useRef<any>()
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  const width = (widthContent - (heightContent * 16 / 15 + 160)) / 2;

  useEffect(() => {

    if(ref.current) {
      // ref.current.addEventListener('wheel', handleWheel);
    }
  });

  return (
    <div ref={ref} className={'sider-left'} style={{
      width: width,
      fontSize: widthContent / 180, // was 160
      height: heightContent * 0.8,
      fontWeight: isSafari ? 500 : 700
    }}>
      <Intro/>
      <LoadingCapabilities/>
      <Processing/>
    </div>
  )
};

export default Index;
