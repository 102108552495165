export const handleWheel = (event: { deltaY: any; preventDefault: () => void; }) => {
  // const deltaY = event.deltaY;
  // if (deltaY <= 1) {
  //   event.preventDefault();
  // }
  event.preventDefault();
};

export const handleDefaultWheel = (event: { deltaY: any; preventDefault: () => void; }) => {

};

export const handleMiddleClick = (event : MouseEvent) => {
  if (event.button === 1) {
    event.preventDefault();
  }
}