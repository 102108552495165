import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { isSafari } from 'react-device-detect';
import ButtonRight from './ButtonRight';
import { MAIL_CONFIG } from '../../../config';
import { useEffect, useRef, useState } from 'react';

const ContactInfo = () => {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  const size = heightContent / 9 + widthContent / 16;
  const contactRef = useRef<HTMLDivElement>(null);
  const [itemPadding, setPadding] = useState(0);

  useEffect(() => {
    if (contactRef.current) {
      const item = document.querySelector('.right-section-padding') as HTMLParagraphElement;
      if (item) {
        // item.style.paddingBottom = `${contactRef.current.clientHeight * 0.25}px`;
      }

    }
  }, [contactRef])

  const _goList = () => {
    window.location.href = `mailto:${MAIL_CONFIG}`;
  };

  return (
    <div className={'content-right-box'}>
      <div className={isSafari ? 'right-info-container-safari' : 'right-info-container'}>
        <div className='right-section justify-start'>
          <p className='text-gray'>{'< Reach >'}</p>
          <p>195 Pearl's Hill Terrace
            <br />
            #03-76 Singapore 168976
          </p>
        </div>
        <div className='right-section justify-center'>
          <p className='text-gray'>{'< or >'}</p>
        </div>
        <div className='right-section justify-end'>
          <ButtonRight goList={_goList} size={size} />
        </div>
      </div>
    </div>
  )
};

export default ContactInfo