/*
  This component reveals a grid of SVG rectangles as the user scrolls 
  down the page. The grid is either shuffled or processed based on 
  grayscale values from an image. It uses Redux to dynamically get 
  content width and height. The grayscale values are sorted and the 
  grid is revealed based on the scroll position.
*/

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';
import { useLocation } from 'react-router-dom';
import GridRenderSingleton from './GridRender';
import FaderSingleton from './Fader';

const CustomGridReveal: React.FC = () => {
  // Get the current width and height of the content from Redux
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);

  // States for shuffled indices and the generated HTML content for SVG
  const [htmlContent, setHtmlContent] = useState<JSX.Element | null>(null);
  const fadeDuration = 1; // How long to transit from Title page to Main page

  // Location
  const location = useLocation();
  const [isMainPage, setMainPage] = useState(false);

  const gridInstance = GridRenderSingleton.getInstance();
  const dimX = gridInstance.dimX;
  const dimY = gridInstance.dimY;
  const fader = FaderSingleton.getInstance();

  useEffect(() => {
    if (htmlContent) {
      gridInstance.updateRectRef();

      if (!isMainPage) {
        gridInstance.hideRect();
        fader.setFadedIn(false);
        const titlePanel = document.querySelector('.title-panel') as HTMLDivElement;
        titlePanel.style.zIndex = '0';
      }
    }
  }, [htmlContent, isMainPage]);

  useEffect(() => {
    const isMain = location.pathname === '/';
    setMainPage(isMain);
  }, [location]);

  // Effect to handle grid cell opacity based on scroll progress
  useEffect(() => {
    setHtmlContent(generatedHtml);

    // Function to control opacity of grid cells based on scroll position
    // function hideGridCells() {
    //   let minValue = document.documentElement.scrollHeight - window.innerHeight;
    //   minValue = (minValue === 0) ? 1 : minValue; // Prevent division by zero
    //   const clampedProgress = Math.min(Math.max(window.scrollY / minValue, 0.0), 1.0);

    //   const scrollPercent = Math.round(clampedProgress * 100) / 100;
    //   const cellsToReveal = Math.floor(scrollPercent * totalCells);

    //   // Use requestAnimationFrame for smoother updates
    //   requestAnimationFrame(() => {
    //     if (rectsRef.current) {
    //       rectsRef.current.forEach((rect, index) => {
    //         const newOpacity = gridInstance.getIndices()[index] < cellsToReveal ? '1' : '0';
    //         rect.setAttribute('opacity', newOpacity);
    //       });
    //     }
    //   });
    // };

    const updateGrid = (e: { deltaY: any; }) => {
      const scrollingDown = (e.deltaY > 0) ? true : false;

      if (scrollingDown) {
        fader.startFadeOut(fadeDuration);
      }
      // else if (!scrollingDown) {
      //   fader.startFadeIn(fadeDuration);
      // }
    }

    // Add scroll event listener
    if (isMainPage) {
      window.addEventListener('wheel', updateGrid);
    }

    return () => {
      // Cleanup scroll event listener on component unmount
      if (isMainPage) {
        window.removeEventListener('wheel', updateGrid);
      }
    };
  }, [gridInstance, widthContent, window.innerHeight, isMainPage]); // Rerun on shuffled indices, content size change

  // Generate the grid content for the mask
  const generatedHtml = (
    <mask id="grid-mask" x="0" y="0" width={window.innerWidth} height={window.innerHeight}>
      {Array.from({ length: dimX * dimY }, (_, index) => {
        const row = Math.floor(index / dimX);
        const col = (index % dimX);
        const cellWidth = (window.innerWidth / dimX);  // Cell width based on content width
        const cellHeight = (window.innerHeight / dimY); // Cell height based on content height
        return (
          <rect
            className='grid-rect'
            style={{ transition: 'opacity 0.2s ease-out', gap: '0' }}
            key={index}
            x={col * cellWidth}
            y={row * cellHeight}
            width={cellWidth}
            height={cellHeight}
            fill="white"
            opacity={1} // Initially hidden
            shapeRendering='crispEdges'
          />
        );
      })}
    </mask>
  );

  // Render an SVG element with the grid mask defined in defs
  return (
    <svg width="0" height="0">
      <defs>
        {htmlContent}
      </defs>
    </svg>
  );
};

export default CustomGridReveal;
