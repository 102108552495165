enum DIMENSION { // Row x Column of the ImageBox
  OneByOne,
  OneByTwo, // Horizontal
  TwoByOne, // Vertical
  TwoByTwo
}

export interface ImageItemProps {
  file: string,             // The string reference to the file
  duration: number,         // How long the item should be played
  initialDelay: number,     // Delay before playing the current item in seconds
  delayToNextItem: number,  // Delay before playing the next item in seconds
  link: string,             // A reference to the webpage of the project
  row: number,              // The row that the item should be displayed on between 0 and 2
  column: number,           // The column that the item should be displayed on between 0 and 3
  dim: DIMENSION            // The size of the video default at 0, which is a one by one size
}

export const LIST_IMAGE_ITEMS: ImageItemProps[] = [
  {
    file: "ARC_LONG",
    duration: 4,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "ARC_COMMUNITY",
    row: 0,
    column: 0,
    dim: DIMENSION.OneByTwo
  },
  {
    file: "CARNIVAL",
    duration: 5,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "CARNIVAL",
    row: 0,
    column: 0,
    dim: DIMENSION.OneByOne
  },
  {
    file: "PICKERING",
    duration: 5,
    initialDelay: 4,
    delayToNextItem: 10,
    link: "",
    row: 0,
    column: 1,
    dim: DIMENSION.TwoByOne
  },
  {
    file: "BIORE_GIF",
    duration: 4,
    initialDelay: 3,
    delayToNextItem: 5,
    link: "BIOR",
    row: 0,
    column: 2,
    dim: DIMENSION.OneByOne
  },
  {
    file: "MEEP_LONG",
    duration: 6,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "THE_OTHER_SIDE_NFT",
    row: 0,
    column: 2,
    dim: DIMENSION.OneByTwo
  },
  {
    file: "THEOTHERLIFE",
    duration: 5,
    initialDelay: 10,
    delayToNextItem: 5,
    link: "",
    row: 1,
    column: 0,
    dim: DIMENSION.OneByOne
  },
  {
    file: "CHANGI",
    duration: 5,
    initialDelay: 0,
    delayToNextItem: 21,
    link: "",
    row: 1,
    column: 0,
    dim: DIMENSION.TwoByOne
  },
  {
    file: "TEMASEKTRUST",
    duration: 5,
    initialDelay: 10,
    delayToNextItem: 4,
    link: "",
    row: 1,
    column: 1,
    dim: DIMENSION.OneByOne
  },
  {
    file: "EXO",
    duration: 3,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "",
    row: 1,
    column: 2,
    dim: DIMENSION.OneByOne
  },
  {
    file: "SUISAI_GIF",
    duration: 5,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "SUISAI",
    row: 1,
    column: 2,
    dim: DIMENSION.OneByOne
  },
  {
    file: "TOWERBOX",
    duration: 4,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "",
    row: 1,
    column: 3,
    dim: DIMENSION.OneByOne
  },
  {
    file: "SKILL_FEATURE",
    duration: 7,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "SKILLSFUTURE",
    row: 1,
    column: 3,
    dim: DIMENSION.OneByOne
  },
  {
    file: "GOLDFISH",
    duration: 5,
    initialDelay: 0,
    delayToNextItem: 3,
    link: "",
    row: 1,
    column: 3,
    dim: DIMENSION.TwoByOne
  },
  {
    file: "BLOB_LONG",
    duration: 4,
    initialDelay: 3,
    delayToNextItem: 5,
    link: "",
    row: 2,
    column: 0,
    dim: DIMENSION.OneByTwo
  },
  {
    file: "MALING",
    duration: 6,
    initialDelay: 0,
    delayToNextItem: 5,
    link: "",
    row: 2,
    column: 0,
    dim: DIMENSION.OneByOne
  },
  {
    file: "MOONCAKE_LONG",
    duration: 4,
    initialDelay: 7,
    delayToNextItem: 5,
    link: "MID_AUTUMN_FESTIVAL",
    row: 2,
    column: 2,
    dim: DIMENSION.OneByTwo
  },
  {
    file: "BUZZ",
    duration: 4,
    initialDelay: 0,
    delayToNextItem: 9,
    link: "THE_OTHER_SIDE_NFT",
    row: 2,
    column: 2,
    dim: DIMENSION.OneByOne
  },
]