import { useEffect, useState } from "react";
import { RouterName } from "../../../enum/RouterEnum";
import { ProjectModel } from "../../../models/Project";
import ImageBox from "./ImageBox";
import { LIST_IMAGE_ITEMS } from "./ImageDatas";
import { useNavigate } from "react-router-dom";
import api from "../../../api";

interface RenderProp {
  widthTd: number;
}

// Insert the images into the invidiual elements of the table
const RenderImageBoxes: React.FC<RenderProp> = ({ widthTd }) => {
  const rows = 3;
  const cols = 4
  const grid = Array.from({ length: rows }, () => Array(cols).fill(null));
  const navigate = useNavigate();
  const [listProject, setListProject] = useState<ProjectModel[]>([]);

  // Place items into the grid cells based on their row and column
  LIST_IMAGE_ITEMS.forEach(item => {
    if (item.row < rows && item.column < cols) {
      grid[item.row][item.column] = grid[item.row][item.column] || [];
      grid[item.row][item.column].push(item); // Add the item to the appropriate cell
    }
    else {
      console.error("Error: The Row or Column that you have specified is out of bounds, please choose a smaller value!");
    }

  });

  useEffect(() => {
    _getAllProject().then();
  }, []);

  const _getAllProject = async () => {
    try {
      const res = await api.project.getAllProject();
      const listSort = res.data.sort((a: ProjectModel, b: ProjectModel) => a.order - b.order).filter(
        (item: ProjectModel) => item.status
      );;
      setListProject(listSort);
    } catch (err) {
    }
  };

  const _gotoDetail = (link: string) => {
    let charExits = /\W/g;

    const project = listProject.find(item => item.title.replaceAll(" ", "_").replace(charExits, '') === link);
    if (project) {
      const path = project.title.replaceAll(" ", "_").replace(charExits, '')
      navigate(`${RouterName.projectDetail}/${path}`, {
        state: project
      });
    }
  };

  return (
    <tbody>
      {grid.map((row, rowIndex) => (
        <tr key={`row${rowIndex}`}>
          {row.map((cell, colIndex) => (
            <td key={`col${colIndex}`} className={'td-table-container'} style={{ width: widthTd, height: widthTd }}>
              {cell && <ImageBox key={`cell${colIndex + rowIndex * rows}`} items={cell} widthTd={widthTd} callback={_gotoDetail} />}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
};

export default RenderImageBoxes;