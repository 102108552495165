import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";

interface Props {
  row: number;
  isList?: boolean;
}

function createArrayUpToX(x: number) {
  var array = [];
  for (var i = 0; i <= x; i++) {
    array.push(i);
  }
  return array;
};

const LineHome = (props: Props) => {
  const {row, isList} = props;
  const array = createArrayUpToX(row);
  const height = useSelector((state: RootState) => state.sizeContent.height);
  const widthTd = height * 0.8 / 3;

  const borderRight = (itemDiv: number, itemChildren: number) => {
    let border = '1px solid';
    if (itemDiv === 0 && itemChildren === 0 && isList ||
      itemDiv === 0 && itemChildren === 1 && isList) {
      border = 'none'
    }
    if (itemDiv) {
      if ((itemChildren === 0 || itemChildren === 2) && isList) {
        border = 'none'
      }
    }
    return border;
  };

  const borderLeft = (itemDiv: number, itemChildren: number) => {
    let border = 'none';
    if (!isList) {
      if (!itemChildren) {
        border = '1px solid';
      } else {
        border = 'none'
      }
    } else {
      if (!itemChildren) {
        border = '1px solid';
      }
      if (itemDiv && (itemChildren === 1 || itemChildren === 3)) {
        return border = 'none';
      }
    }
    return border;
  }

  const _renderDivChildren = (itemDiv: number, itemChildren: number) => {
    return (
      <div
        className='col-home-content'
        style={{
          width: widthTd, height: widthTd,
        }}
        key={itemChildren}
      >
        <div
          className="col-home-x"
          style={{
            borderTop: itemDiv ? 'none' : '1px solid',
            borderBottom: '1px solid',
          }}/>
        <div
          className="col-home-y"
          style={{
            borderLeft: borderLeft(itemDiv, itemChildren),
            borderRight: borderRight(itemDiv, itemChildren),
          }}
        />
      </div>
    )
  };

  const _renderDiv = (itemDiv: number) => {
    return (
      <div
        className="div-parent"
        key={itemDiv}
        style={{
          marginBottom: itemDiv === array.length - 1 ? 100 : 0,

        }}
      >
        {[0, 1, 2, 4].map(itemChildren => {
          return _renderDivChildren(itemDiv, itemChildren)
        })}
      </div>
    )
  };

  return (
    <div className="line-home-container">
      <div className="line-home-box">
        {array.map(itemDiv => {
          return _renderDiv(itemDiv)
        })}
      </div>
    </div>
  )
}

export default LineHome;
