import React, { useEffect } from 'react';
import TextHeader from '../../../component/Desktop/TextHeader';
import { Layout } from "antd";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { handleWheel } from '../../../ultils/common';
const { Header } = Layout;

export default function HeaderDesktop() {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);

  const lineWidth = `${widthContent * 0.45 - 40}px`;
  const topOffset = `${(window.innerHeight - heightContent) * 0.5}px`;
  const leftOffset = `${(window.innerWidth - widthContent) * 0.5}px`;
  const screenWidth = window.innerWidth;

  useEffect(() => {
    const container = document.querySelector('.header-desktop-container') as HTMLElement | null;

    container?.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      container?.removeEventListener('wheel', handleWheel);
    }
  }, []);

  const _header = () => {
    return (
      <div className={'header-info-container'}
        style={{
          height: heightContent / 10,
          width: widthContent,
        }}
      >
        <div className="line-header"
          style={{
            '--line-width': lineWidth
          } as React.CSSProperties} />
        <TextHeader />
        <div className="line-header"
          style={{
            '--line-width': lineWidth
          } as React.CSSProperties} />
      </div>
    )
  }

  return (
    <Header className='header-desktop-container'
      style={{
        '--offset-from-top': topOffset,
        '--offset-from-left': leftOffset,
        height: heightContent / 10,
        width: widthContent,
      } as React.CSSProperties}>
      {_header()}
    </Header>
  )
}
